<template>
    <div class="container">
        <v-card width="400" class="mx-auto mt-5">
            <v-card-title>
                <h1>
                    Authentifizierung
                </h1>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-text-field
                        label="Handynummer (letzte 4 Ziffern)"
                        prepend-icon="mdi-phone"
                        v-model="digits"
                        :rules="digitRules"
                    />
                    <v-text-field
                        label="Bestätigungscode"
                        prepend-icon="mdi-lock"
                        v-model="verificationCode"
                    />
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn color="success" :active="!!digits">Anfordern</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="info">Bestätigen</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {

}
</script>